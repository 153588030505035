import React from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const OpenRekening = () => {
    const isFeatureEnabled = useFeatureIsOn("eform-ikd-floating-button");
    if (!isFeatureEnabled) {
        return null;
    }
    const handleRedirect = () => {
        window.open('https://eform-ikd.bankdki.co.id', '_blank');
    };

    return (
        <div
            role="button"
            style={{
                backgroundColor: "#a02128",
                textAlign: 'center',
                padding: '6px 14px',
                borderRadius: '20px',
                fontWeight: 'bold',
                position: "fixed",
                bottom: 32,
                right: 100,
                zIndex: 999999,
                cursor: 'pointer',
                color: 'white',
                border: '2px solid white',
                boxShadow: '7px 5px 15px 5px rgba(0,0,0,0.1)'
            }}
            onClick={handleRedirect}
            className="OpenRekening"
        >
            Buka Rekening
            Bank DKI Sekarang
        </div >
    );
};

export default OpenRekening;
